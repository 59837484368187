<script setup>
import { useUserStore } from '~/stores/user.store';

const userStore = useUserStore();

const user = computed(() => userStore.userGetter());

const form = ref({
  first_name: user.value?.first_name,
  last_name: user.value?.last_name,
});

const submit = async () => {
  if (form.value.first_name === user.value?.first_name && form.value.last_name === user.value?.last_name) return;
  const res = await userStore.changeName(form.value);
  if (res.status !== 200) return;
  await userStore.getUserData();
  form.value = {
    first_name: user.value?.first_name,
    last_name: user.value?.last_name,
  };
}
</script>
<template>
  <NuxtLayout name="mobile-base">

    <template #mobile-header>
      <mobile-header :title="$t('client.change_name')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <template v-if="user">
      <div class="mt-3 px-4">
        <WInput type="text" name="first_name" :placeholder="$t('client.first_name')" v-model:value="form.first_name" />
      </div>
      <div class="mt-3 px-4">
        <WInput type="text" name="last_name" :placeholder="$t('client.last_name')" v-model:value="form.last_name" />
      </div>
      <div class="mt-6 px-4 flex items-center gap-3">
        <button @click="submit"
          class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">Update</button>
      </div>
    </template>
    <template v-else>
      <div class="relative h-screen">
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <span class="loader"></span>
        </div>
      </div>
    </template>
    <template #mobile-footer></template>
  </NuxtLayout>
</template>